import React, { Component } from 'react';
import loading from "../../images/loading2.gif"
import './styles.scss';

class Loading extends Component {

	render() {
		return (
			<div className="loading">
				<img className='loading_gif' src={loading} alt="" />
			</div>
		);
	}
}

export default Loading;
