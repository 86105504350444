import React, { Component } from "react";
import "./styles.scss";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import Logo from "../../pictures/logo.png";
import SearchIcon from "@mui/icons-material/Search";
import s1 from "../../pictures/s1.svg";
import CloseIcon from "@mui/icons-material/Close";

class MenuBar extends Component {
	onSearchSubmit(e) {
		e.preventDefault();
		if (e.target.searchVal.value.length > 0)
			window.location.href = `https://oman.om/%D9%86%D8%AA%D8%A7%D8%A6%D8%AC-%D8%A7%D9%84%D8%A8%D8%AD%D8%AB?indexCatalogue=index-all-&searchQuery=${e.target.searchVal.value}&wordsMode=AllWords`;
	}

	render() {
		const { closeMenu } = this.props;
		return (
			<div className="menu-bar">
				<div className="menu-header" />
				<div className="mobile-menu-header">
					<img className="menu-header-logo" src={Logo} alt="" />
					<button className="mobile-close-menu" onClick={closeMenu}>
						<CloseIcon />
					</button>
				</div>
				<form className="search-form" onSubmit={this.onSearchSubmit}>
					<SearchIcon className="search-icon" />
					<input className="header-search-input" placeholder="كلمة البحث" name="searchVal" autoComplete="off" />
					<button type="submit" className="header-search-btn">
						<img src={s1} alt="" />
					</button>
				</form>
				<div className="menu-btn-wrapper">
					<button
						className="menu-btn"
						onClick={(e) => {
							e.target.closest(".menu-btn-wrapper").classList.toggle("open");
						}}
					>
						<label className="menu-label">المعلومات العامة</label>
						<ArrowBackIosIcon className="menu-icon" />
					</button>
					<div className="menu-dropdown">
						<a
							className="menu-nav-link"
							href="https://oman.om/home-top-level/%D8%A7%D9%84%D8%AD%D9%83%D9%88%D9%85%D8%A9-%D8%A7%D9%84%D9%85%D8%AA%D9%83%D8%A7%D9%85%D9%84%D8%A9/%D8%AD%D9%83%D9%88%D9%85%D8%A9-%D8%B3%D9%84%D8%B7%D9%86%D8%A9-%D8%B9%D9%85%D8%A7%D9%86/%D8%B9%D9%86-%D8%B9%D9%85%D8%A7%D9%86"
						>
							عن عمان
						</a>
						<a
							className="menu-nav-link"
							href="https://oman.om/home-top-level/%D8%A7%D9%84%D8%AD%D9%83%D9%88%D9%85%D8%A9-%D8%A7%D9%84%D9%85%D8%AA%D9%83%D8%A7%D9%85%D9%84%D8%A9/%D8%AD%D9%83%D9%88%D9%85%D8%A9-%D8%B3%D9%84%D8%B7%D9%86%D8%A9-%D8%B9%D9%85%D8%A7%D9%86/%D8%AD%D8%B6%D8%B1%D8%A9-%D8%B5%D8%A7%D8%AD%D8%A8-%D8%A7%D9%84%D8%AC%D9%84%D8%A7%D9%84%D8%A9-%D8%A7%D9%84%D8%B3%D9%84%D8%B7%D8%A7%D9%86-%D9%87%D9%8A%D8%AB%D9%85"
						>
							عن السلطان هيثم
						</a>
					</div>
				</div>
				<div className="menu-btn-wrapper">
					<button
						className="menu-btn"
						onClick={(e) => {
							e.target.closest(".menu-btn-wrapper").classList.toggle("open");
						}}
					>
						<label className="menu-label">مواطن</label>
						<ArrowBackIosIcon className="menu-icon" />
					</button>
					<div className="menu-dropdown">
						<a
							className="menu-nav-link"
							href="https://oman.om/%d8%a7%d9%84%d9%85%d8%a4%d8%b3%d8%b3%d8%a7%d8%aa-%d8%a7%d9%84%d8%ad%d9%83%d9%88%d9%85%d9%8a%d8%a9/%d8%aa%d9%81%d8%a7%d8%b5%d9%8a%d9%84-%d8%a7%d9%84%d9%85%d8%a4%d8%b3%d8%b3%d8%a9/Index?ID=19508"
						>
							الطفولة
						</a>
						<a
							className="menu-nav-link"
							href="https://oman.om/%d8%a7%d9%84%d9%85%d8%a4%d8%b3%d8%b3%d8%a7%d8%aa-%d8%a7%d9%84%d8%ad%d9%83%d9%88%d9%85%d9%8a%d8%a9/%d8%aa%d9%81%d8%a7%d8%b5%d9%8a%d9%84-%d8%a7%d9%84%d9%85%d8%a4%d8%b3%d8%b3%d8%a9/Index?ID=21810"
						>
							التعليم
						</a>
						<a
							className="menu-nav-link"
							href="https://oman.om/%d8%a7%d9%84%d9%85%d8%a4%d8%b3%d8%b3%d8%a7%d8%aa-%d8%a7%d9%84%d8%ad%d9%83%d9%88%d9%85%d9%8a%d8%a9/%d8%aa%d9%81%d8%a7%d8%b5%d9%8a%d9%84-%d8%a7%d9%84%d9%85%d8%a4%d8%b3%d8%b3%d8%a9/Index?ID=25427"
						>
							الحياة في عمان
						</a>
						<a
							className="menu-nav-link"
							href="https://oman.om/%d8%a7%d9%84%d9%85%d8%a4%d8%b3%d8%b3%d8%a7%d8%aa-%d8%a7%d9%84%d8%ad%d9%83%d9%88%d9%85%d9%8a%d8%a9/%d8%aa%d9%81%d8%a7%d8%b5%d9%8a%d9%84-%d8%a7%d9%84%d9%85%d8%a4%d8%b3%d8%b3%d8%a9/Index?ID=20693"
						>
							الحصول على وظيفة
						</a>
						<a
							className="menu-nav-link"
							href="https://oman.om/%d8%a7%d9%84%d9%85%d8%a4%d8%b3%d8%b3%d8%a7%d8%aa-%d8%a7%d9%84%d8%ad%d9%83%d9%88%d9%85%d9%8a%d8%a9/%d8%aa%d9%81%d8%a7%d8%b5%d9%8a%d9%84-%d8%a7%d9%84%d9%85%d8%a4%d8%b3%d8%b3%d8%a9/Index?ID=18352"
						>
							البدء بمشروعك الخاص
						</a>
						<a
							className="menu-nav-link"
							href="https://oman.om/%d8%a7%d9%84%d9%85%d8%a4%d8%b3%d8%b3%d8%a7%d8%aa-%d8%a7%d9%84%d8%ad%d9%83%d9%88%d9%85%d9%8a%d8%a9/%d8%aa%d9%81%d8%a7%d8%b5%d9%8a%d9%84-%d8%a7%d9%84%d9%85%d8%a4%d8%b3%d8%b3%d8%a9/Index?ID=19595"
						>
							امتلاك سيارة
						</a>
						<a
							className="menu-nav-link"
							href="https://oman.om/%d8%a7%d9%84%d9%85%d8%a4%d8%b3%d8%b3%d8%a7%d8%aa-%d8%a7%d9%84%d8%ad%d9%83%d9%88%d9%85%d9%8a%d8%a9/%d8%aa%d9%81%d8%a7%d8%b5%d9%8a%d9%84-%d8%a7%d9%84%d9%85%d8%a4%d8%b3%d8%b3%d8%a9/Index?ID=22066"
						>
							الحصول على مسكن
						</a>
						<a
							className="menu-nav-link"
							href="https://oman.om/%d8%a7%d9%84%d9%85%d8%a4%d8%b3%d8%b3%d8%a7%d8%aa-%d8%a7%d9%84%d8%ad%d9%83%d9%88%d9%85%d9%8a%d8%a9/%d8%aa%d9%81%d8%a7%d8%b5%d9%8a%d9%84-%d8%a7%d9%84%d9%85%d8%a4%d8%b3%d8%b3%d8%a9/Index?ID=25443"
						>
							العائلة
						</a>
						<a
							className="menu-nav-link"
							href="https://oman.om/%d8%a7%d9%84%d9%85%d8%a4%d8%b3%d8%b3%d8%a7%d8%aa-%d8%a7%d9%84%d8%ad%d9%83%d9%88%d9%85%d9%8a%d8%a9/%d8%aa%d9%81%d8%a7%d8%b5%d9%8a%d9%84-%d8%a7%d9%84%d9%85%d8%a4%d8%b3%d8%b3%d8%a9/Index?ID=21628"
						>
							التقاعد
						</a>
					</div>
				</div>
				<div className="menu-btn-wrapper">
					<button
						className="menu-btn"
						onClick={(e) => {
							e.target.closest(".menu-btn-wrapper").classList.toggle("open");
						}}
					>
						<label className="menu-label">مستثمر</label>
						<ArrowBackIosIcon className="menu-icon" />
					</button>
					<div className="menu-dropdown">
						<a
							className="menu-nav-link"
							href="https://oman.om/%d8%a7%d9%84%d9%85%d8%a4%d8%b3%d8%b3%d8%a7%d8%aa-%d8%a7%d9%84%d8%ad%d9%83%d9%88%d9%85%d9%8a%d8%a9/%d8%aa%d9%81%d8%a7%d8%b5%d9%8a%d9%84-%d8%a7%d9%84%d9%85%d8%a4%d8%b3%d8%b3%d8%a9/Index?ID=25225"
						>
							البدء بمشروعك الخاص
						</a>
						<a
							className="menu-nav-link"
							href="https://oman.om/%d8%a7%d9%84%d9%85%d8%a4%d8%b3%d8%b3%d8%a7%d8%aa-%d8%a7%d9%84%d8%ad%d9%83%d9%88%d9%85%d9%8a%d8%a9/%d8%aa%d9%81%d8%a7%d8%b5%d9%8a%d9%84-%d8%a7%d9%84%d9%85%d8%a4%d8%b3%d8%b3%d8%a9/Index?ID=23648"
						>
							تطوير المشاريع
						</a>
						<a
							className="menu-nav-link"
							href="https://oman.om/%d8%a7%d9%84%d9%85%d8%a4%d8%b3%d8%b3%d8%a7%d8%aa-%d8%a7%d9%84%d8%ad%d9%83%d9%88%d9%85%d9%8a%d8%a9/%d8%aa%d9%81%d8%a7%d8%b5%d9%8a%d9%84-%d8%a7%d9%84%d9%85%d8%a4%d8%b3%d8%b3%d8%a9/Index?ID=12250"
						>
							الأنشطة والتراخيص
						</a>
						<a
							className="menu-nav-link"
							href="https://oman.om/%d8%a7%d9%84%d9%85%d8%a4%d8%b3%d8%b3%d8%a7%d8%aa-%d8%a7%d9%84%d8%ad%d9%83%d9%88%d9%85%d9%8a%d8%a9/%d8%aa%d9%81%d8%a7%d8%b5%d9%8a%d9%84-%d8%a7%d9%84%d9%85%d8%a4%d8%b3%d8%b3%d8%a9/Index?ID=25184"
						>
							الضرائب
						</a>
						<a
							className="menu-nav-link"
							href="https://oman.om/%d8%a7%d9%84%d9%85%d8%a4%d8%b3%d8%b3%d8%a7%d8%aa-%d8%a7%d9%84%d8%ad%d9%83%d9%88%d9%85%d9%8a%d8%a9/%d8%aa%d9%81%d8%a7%d8%b5%d9%8a%d9%84-%d8%a7%d9%84%d9%85%d8%a4%d8%b3%d8%b3%d8%a9/Index?ID=12142"
						>
							الجمارك
						</a>
					</div>
				</div>
				<div className="menu-btn-wrapper">
					<button
						className="menu-btn"
						onClick={(e) => {
							e.target.closest(".menu-btn-wrapper").classList.toggle("open");
						}}
					>
						<label className="menu-label">مقيم</label>
						<ArrowBackIosIcon className="menu-icon" />
					</button>
					<div className="menu-dropdown">
						<a
							className="menu-nav-link"
							href="https://oman.om/%d8%a7%d9%84%d9%85%d8%a4%d8%b3%d8%b3%d8%a7%d8%aa-%d8%a7%d9%84%d8%ad%d9%83%d9%88%d9%85%d9%8a%d8%a9/%d8%aa%d9%81%d8%a7%d8%b5%d9%8a%d9%84-%d8%a7%d9%84%d9%85%d8%a4%d8%b3%d8%b3%d8%a9/Index?ID=19508"
						>
							الطفولة
						</a>
						<a
							className="menu-nav-link"
							href="https://oman.om/%d8%a7%d9%84%d9%85%d8%a4%d8%b3%d8%b3%d8%a7%d8%aa-%d8%a7%d9%84%d8%ad%d9%83%d9%88%d9%85%d9%8a%d8%a9/%d8%aa%d9%81%d8%a7%d8%b5%d9%8a%d9%84-%d8%a7%d9%84%d9%85%d8%a4%d8%b3%d8%b3%d8%a9/Index?ID=20645"
						>
							التعليم
						</a>
						<a
							className="menu-nav-link"
							href="https://oman.om/%d8%a7%d9%84%d9%85%d8%a4%d8%b3%d8%b3%d8%a7%d8%aa-%d8%a7%d9%84%d8%ad%d9%83%d9%88%d9%85%d9%8a%d8%a9/%d8%aa%d9%81%d8%a7%d8%b5%d9%8a%d9%84-%d8%a7%d9%84%d9%85%d8%a4%d8%b3%d8%b3%d8%a9/Index?ID=19507"
						>
							الحياة في عمان
						</a>
						<a
							className="menu-nav-link"
							href="https://oman.om/%d8%a7%d9%84%d9%85%d8%a4%d8%b3%d8%b3%d8%a7%d8%aa-%d8%a7%d9%84%d8%ad%d9%83%d9%88%d9%85%d9%8a%d8%a9/%d8%aa%d9%81%d8%a7%d8%b5%d9%8a%d9%84-%d8%a7%d9%84%d9%85%d8%a4%d8%b3%d8%b3%d8%a9/Index?ID=20693"
						>
							الحصول على وظيفة
						</a>
						<a
							className="menu-nav-link"
							href="https://oman.om/%d8%a7%d9%84%d9%85%d8%a4%d8%b3%d8%b3%d8%a7%d8%aa-%d8%a7%d9%84%d8%ad%d9%83%d9%88%d9%85%d9%8a%d8%a9/%d8%aa%d9%81%d8%a7%d8%b5%d9%8a%d9%84-%d8%a7%d9%84%d9%85%d8%a4%d8%b3%d8%b3%d8%a9/Index?ID=25225"
						>
							البدء بمشروعك الخاص
						</a>
						<a
							className="menu-nav-link"
							href="https://oman.om/%d8%a7%d9%84%d9%85%d8%a4%d8%b3%d8%b3%d8%a7%d8%aa-%d8%a7%d9%84%d8%ad%d9%83%d9%88%d9%85%d9%8a%d8%a9/%d8%aa%d9%81%d8%a7%d8%b5%d9%8a%d9%84-%d8%a7%d9%84%d9%85%d8%a4%d8%b3%d8%b3%d8%a9/Index?ID=19595"
						>
							امتلاك سيارة
						</a>
						<a
							className="menu-nav-link"
							href="https://oman.om/%d8%a7%d9%84%d9%85%d8%a4%d8%b3%d8%b3%d8%a7%d8%aa-%d8%a7%d9%84%d8%ad%d9%83%d9%88%d9%85%d9%8a%d8%a9/%d8%aa%d9%81%d8%a7%d8%b5%d9%8a%d9%84-%d8%a7%d9%84%d9%85%d8%a4%d8%b3%d8%b3%d8%a9/Index?ID=22083"
						>
							الحصول على مسكن
						</a>
						<a
							className="menu-nav-link"
							href="https://oman.om/%d8%a7%d9%84%d9%85%d8%a4%d8%b3%d8%b3%d8%a7%d8%aa-%d8%a7%d9%84%d8%ad%d9%83%d9%88%d9%85%d9%8a%d8%a9/%d8%aa%d9%81%d8%a7%d8%b5%d9%8a%d9%84-%d8%a7%d9%84%d9%85%d8%a4%d8%b3%d8%b3%d8%a9/LifeEvent?ID=1&EntText=LifeEvent&lifEvent=1&Persona=4"
						>
							العائلة
						</a>
						<a
							className="menu-nav-link"
							href="https://oman.om/%d8%a7%d9%84%d9%85%d8%a4%d8%b3%d8%b3%d8%a7%d8%aa-%d8%a7%d9%84%d8%ad%d9%83%d9%88%d9%85%d9%8a%d8%a9/%d8%aa%d9%81%d8%a7%d8%b5%d9%8a%d9%84-%d8%a7%d9%84%d9%85%d8%a4%d8%b3%d8%b3%d8%a9/LifeEvent?ID=13&EntText=LifeEvent&lifEvent=13&Persona=4"
						>
							التقاعد
						</a>
					</div>
				</div>
				<div className="menu-btn-wrapper">
					<button
						className="menu-btn"
						onClick={(e) => {
							e.target.closest(".menu-btn-wrapper").classList.toggle("open");
						}}
					>
						<label className="menu-label">البرنامج الوطني للإقتصاد الرقمي</label>
						<ArrowBackIosIcon className="menu-icon" />
					</button>
					<div className="menu-dropdown">
						<a
							className="menu-nav-link"
							href="https://oman.om/%D8%A7%D9%84%D8%A8%D8%B1%D9%86%D8%A7%D9%85%D8%AC-%D8%A7%D9%84%D9%88%D8%B7%D9%86%D9%8A-%D9%84%D9%84%D8%A7%D9%82%D8%AA%D8%B5%D8%A7%D8%AF-%D8%A7%D9%84%D8%B1%D9%82%D9%85%D9%8A"
						>
							البرنامج الوطني للاقتصاد الرقمي
						</a>
						<a
							className="menu-nav-link"
							href="https://oman.om/home-top-level/%D8%A7%D9%84%D8%AD%D9%83%D9%88%D9%85%D8%A9-%D8%A7%D9%84%D9%85%D8%AA%D9%83%D8%A7%D9%85%D9%84%D8%A9/%D8%A7%D9%84%D8%AD%D9%83%D9%88%D9%85%D8%A9-%D8%A7%D9%84%D8%A5%D9%84%D9%83%D8%AA%D8%B1%D9%88%D9%86%D9%8A%D8%A9/%D8%A8%D8%B1%D9%86%D8%A7%D9%85%D8%AC-%D8%A7%D9%84%D8%AA%D8%AD%D9%88%D9%84-%D8%A7%D9%84%D8%B1%D9%82%D9%85%D9%8A"
						>
							برنامج التحول الرقمي
						</a>
						<a
							className="menu-nav-link"
							href="https://oman.om/home-top-level/%D8%A7%D9%84%D9%85%D8%B4%D8%A7%D8%B1%D9%83%D8%A9-%D8%A7%D9%84%D8%A5%D9%84%D9%83%D8%AA%D8%B1%D9%88%D9%86%D9%8A%D8%A9/%D8%A7%D9%84%D8%B0%D9%83%D8%A7%D8%A1-%D8%A7%D9%84%D8%A7%D8%B5%D8%B7%D9%86%D8%A7%D8%B9%D9%8A-%D9%88%D8%A7%D9%84%D8%AA%D9%82%D9%86%D9%8A%D8%A7%D8%AA-%D8%A7%D9%84%D9%85%D8%AA%D9%82%D8%AF%D9%85%D8%A9"
						>
							برنامج الذكاء الاصطناعي والفضاء
						</a>
					</div>
				</div>
				<div className="menu-btn-wrapper">
					<button
						className="menu-btn"
						onClick={(e) => {
							window.location.href = "https://oman.om/home-top-level/%D8%A7%D9%84%D9%85%D8%B4%D8%A7%D8%B1%D9%83%D8%A9-%D8%A7%D9%84%D8%A5%D9%84%D9%83%D8%AA%D8%B1%D9%88%D9%86%D9%8A%D8%A9";
						}}
					>
						<label className="menu-label">المشاركة الإلكترونية</label>
					</button>
				</div>
				<div className="menu-btn-wrapper">
					<button
						className="menu-btn"
						onClick={(e) => {
							window.location.href = "https://oman.om/home-top-level/%D8%A7%D9%84%D8%A8%D9%8A%D8%A7%D9%86%D8%A7%D8%AA-%D8%A7%D9%84%D9%85%D9%81%D8%AA%D9%88%D8%AD%D8%A9";
						}}
					>
						<label className="menu-label">البيانات المفتوحة</label>
					</button>
				</div>
				<div className="menu-btn-wrapper">
					<button
						className="menu-btn"
						onClick={(e) => {
							window.location.href = "https://oman.om/%D8%A7%D9%84%D8%AA%D9%82%D9%86%D9%8A%D8%A7%D8%AA-%D8%A7%D9%84%D9%86%D8%A7%D8%B4%D8%A6%D8%A9";
						}}
					>
						<label className="menu-label">التقنيات الناشئة</label>
					</button>
				</div>
				<div className="menu-btn-wrapper">
					<button
						className="menu-btn"
						onClick={(e) => {
							window.location.href =
								"https://oman.om/%D8%A7%D9%84%D8%B3%D9%8A%D8%A7%D8%B3%D8%A7%D8%AA-%D9%88%D8%A7%D9%84%D9%82%D9%88%D8%A7%D9%86%D9%8A%D9%86-%D9%88%D8%A7%D9%84%D8%A3%D9%86%D8%B8%D9%85%D8%A9";
						}}
					>
						<label className="menu-label">السياسات والقوانين والأنظمة</label>
					</button>
				</div>
				<div className="menu-btn-wrapper">
					<button
						className="menu-btn"
						onClick={(e) => {
							window.location.href = "https://oman.om/%D8%A7%D9%84%D8%AA%D9%86%D9%85%D9%8A%D8%A9-%D8%A7%D9%84%D9%85%D8%B3%D8%AA%D8%AF%D8%A7%D9%85%D8%A9";
						}}
					>
						<label className="menu-label">التنمية المستدامة</label>
					</button>
				</div>
				<div className="menu-btn-wrapper">
					<button
						className="menu-btn"
						onClick={(e) => {
							e.target.closest(".menu-btn-wrapper").classList.toggle("open");
						}}
					>
						<label className="menu-label">البوابات الإلكترونية للمحافظات</label>
						<ArrowBackIosIcon className="menu-icon" />
					</button>
					<div className="menu-dropdown">
						<a className="menu-nav-link" href="http://www.mm.gov.om/ar/Default.aspx">
							محافظة مسقط
						</a>
						<a className="menu-nav-link" href="https://edm.gov.om/">
							محافظة ظفار
						</a>
						<a className="menu-nav-link" href="https://bur.om/index.php">
							محافظة البريمي
						</a>
						<a className="menu-nav-link" href="https://nsg.gov.om/">
							محافظة شمال الشرقية
						</a>
						<a className="menu-nav-link" href="https://www.bng.gov.om/index.php/ar/">
							محافظة شمال الباطنة
						</a>
						<a className="menu-nav-link" href="https://www.mg.gov.om/">
							محافظة مسندم
						</a>
						<a className="menu-nav-link" href="https://www.dg.gov.om/">
							محافظة الداخلية
						</a>
					</div>
				</div>
				<div className="menu-btn-wrapper">
					<button
						className="menu-btn"
						onClick={(e) => {
							window.location.href = "https://oman.om/home-top-level/%D8%A7%D9%84%D8%AD%D9%83%D9%88%D9%85%D8%A9-%D8%A7%D9%84%D9%85%D8%AA%D9%83%D8%A7%D9%85%D9%84%D8%A9/";
						}}
					>
						<label className="menu-label">الحكومة المتكاملة</label>
					</button>
				</div>
				<div className="menu-header rewards" onClick={() => (window.location.href = "https://oman.om/%D8%A7%D9%84%D8%AC%D9%88%D8%A7%D8%A6%D8%B2")} />
				<div className="menu-header dalel" onClick={() => (window.location.href = "https://oman.om/%D8%A7%D9%84%D8%AC%D9%88%D8%A7%D8%A6%D8%B2")} />
			</div>
		);
	}
}

export default MenuBar;
