import React, { Component } from "react";
import "./styles.scss";
import { Breadcrumbs } from "@mui/material";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import Typography from "@mui/material/Typography";
import MenuBar from "../../components/MenuBar";
import { Slide } from "react-slideshow-image";
import "react-slideshow-image/dist/styles.css";
import ScrollContainer from "react-indiana-drag-scroll";
import image1 from "../../pictures/1.svg";
import image2 from "../../pictures/2.svg";
import image3 from "../../pictures/3.svg";
import image4 from "../../pictures/4.svg";
import image5 from "../../pictures/5.svg";
import image6 from "../../pictures/6.svg";
import image7 from "../../pictures/7.svg";
import image8 from "../../pictures/8.svg";
import image9 from "../../pictures/9.svg";
import bg1 from "../../pictures/1.webp";
import bg2 from "../../pictures/2.webp";
import bg3 from "../../pictures/3.webp";
import bg4 from "../../pictures/4.webp";
import bg5 from "../../pictures/5.webp";
import bg6 from "../../pictures/6.webp";
import homeBottom from "../../pictures/homeBottom.png";
import homeBottomMobile from "../../pictures/homeBottomMobile.png";

const breadcrumbs = [<Typography key="3">الرئيسية</Typography>];

const slideImages = [
	{
		url: require("../../pictures/slide1.png"),
	},
	{
		url: require("../../pictures/slide2.png"),
	},
	{
		url: require("../../pictures/slide3.png"),
	},
	{
		url: require("../../pictures/slide4.jpg"),
	},
	{
		url: require("../../pictures/slide5.png"),
	},
];

const services = [
	{
		title: "تقديم بلاغ",
		link: "/Khedma",
		descrioption: "هذه الخدمة تمكنك من تقديم بلاغ أو شكوى للجهات المختصة بسهولة ويسر.",
	},
	{
		title: "قيد واقعتي الميلاد والوفاة",
		link: "https://business.gov.om/wps/portal/ecr/services/startbusiness/newcr_landing",
		descrioption: "تتيح هذه الخدمة إمكانية قيد واقعة الميلاد والإبلاغ عن حالات الوفاة للعمانيين داخل السلطنة وخارجها وللأجانب المقيمين بها لاستخراج وثيقة رسمية.",
	},
	{
		title: "الإستفسار ودفع المخالفات المرورية",
		link: "https://www.rop.gov.om/OnlineServices/eTraffic/arabic/default.aspx",
		descrioption: "عبر هذه الخدمه يمكن للمواطنين والمقيمين الاستفسار عن المخالفات المرورية ودفعها",
	},
	{
		title: "حجز المواعيد الطبية ",
		link: "https://www.moh.gov.om/ar/-12",
		descrioption: "خدمة حجز المواعيد الطبية تسمح بإدارة مواعيد المريض في المستشفيات ",
	},
	{
		title: "طلب التأشيرة",
		link: "https://evisa.rop.gov.om/ar/home",
		descrioption: "إذا كنت أحد زوار السلطنة للسياحة أو للعمل . هذه الخدمة تساعدك للحصول على تأشيرة الدخول",
	},
	{
		title: "الفواتير",
		link: "https://eservice.oifcoman.com/indvidual.aspx?lang=ar&type=E",
		descrioption: "هذه الخدمة تساعدك على عرض ودفع الكهرباء، المياه والهاتف وتتيح لك ايضا معرفة التعريفات المحليه للكهرباء، المياه والهاتف",
	},
	{
		title: "الضرائب",
		link: "https://tms.taxoman.gov.om/portal/ar/home",
		descrioption: "تعمل الأمانة العامة للضرائب على تنفيذ الاختصاصات المناطة بها والمحددة بالقوانين واللوائح والقرارات الصادرة لتنفيذها",
	},
	{
		title: "تسجيل تجاري",
		link: "https://business.gov.om/wps/portal/ecr/services/startbusiness/newcr_landing",
		descrioption: "هذه الخدمة سوف تساعدك على إنشاء تسجيل تجاري جديد على الانترنت بطريقة بسيطة و سريعة",
	},
];

const lifeDirections = [
	{
		image: image1,
		title: "الطفولة",
		link: "https://oman.om/%d8%a7%d9%84%d9%85%d8%a4%d8%b3%d8%b3%d8%a7%d8%aa-%d8%a7%d9%84%d8%ad%d9%83%d9%88%d9%85%d9%8a%d8%a9/%d8%aa%d9%81%d8%a7%d8%b5%d9%8a%d9%84-%d8%a7%d9%84%d9%85%d8%a4%d8%b3%d8%b3%d8%a9/Index?ID=3&entText=LifeEventOwl",
		descrioption: "يضم جميع الخدمات المتعلقة برعاية الطفل.",
	},
	{
		image: image2,
		title: "التعليم",
		link: "https://oman.om/%d8%a7%d9%84%d9%85%d8%a4%d8%b3%d8%b3%d8%a7%d8%aa-%d8%a7%d9%84%d8%ad%d9%83%d9%88%d9%85%d9%8a%d8%a9/%d8%aa%d9%81%d8%a7%d8%b5%d9%8a%d9%84-%d8%a7%d9%84%d9%85%d8%a4%d8%b3%d8%b3%d8%a9/Index?ID=7&entText=LifeEventOwl",
		descrioption: "يستعرض الخدمات في قطاع التعليم والبحث العلمي.",
	},
	{
		image: image3,
		title: "الحياة في عمان",
		link: "https://oman.om/%d8%a7%d9%84%d9%85%d8%a4%d8%b3%d8%b3%d8%a7%d8%aa-%d8%a7%d9%84%d8%ad%d9%83%d9%88%d9%85%d9%8a%d8%a9/%d8%aa%d9%81%d8%a7%d8%b5%d9%8a%d9%84-%d8%a7%d9%84%d9%85%d8%a4%d8%b3%d8%b3%d8%a9/Index?ID=2&entText=LifeEventOwl",
		descrioption: "يقدم أهم الخدمات للمواطنين والمقيمين في السلطنة.",
	},
	{
		image: image4,
		title: "الحصول على وظيفة",
		link: "https://oman.om/%d8%a7%d9%84%d9%85%d8%a4%d8%b3%d8%b3%d8%a7%d8%aa-%d8%a7%d9%84%d8%ad%d9%83%d9%88%d9%85%d9%8a%d8%a9/%d8%aa%d9%81%d8%a7%d8%b5%d9%8a%d9%84-%d8%a7%d9%84%d9%85%d8%a4%d8%b3%d8%b3%d8%a9/Index?ID=6&entText=LifeEventOwl",
		descrioption: "يهتم بتوفير الخدمات للباحثين عن عمل.",
	},
	{
		image: image5,
		title: "البدء بمشروعك الخاص",
		link: "https://oman.om/%d8%a7%d9%84%d9%85%d8%a4%d8%b3%d8%b3%d8%a7%d8%aa-%d8%a7%d9%84%d8%ad%d9%83%d9%88%d9%85%d9%8a%d8%a9/%d8%aa%d9%81%d8%a7%d8%b5%d9%8a%d9%84-%d8%a7%d9%84%d9%85%d8%a4%d8%b3%d8%b3%d8%a9/Index?ID=5&entText=LifeEventOwl",
		descrioption: "يركّز على الخدمات المقدمة لبدء مشروع في عُمان.",
	},
	{
		image: image6,
		title: "امتلاك سيارة",
		link: "https://oman.om/%d8%a7%d9%84%d9%85%d8%a4%d8%b3%d8%b3%d8%a7%d8%aa-%d8%a7%d9%84%d8%ad%d9%83%d9%88%d9%85%d9%8a%d8%a9/%d8%aa%d9%81%d8%a7%d8%b5%d9%8a%d9%84-%d8%a7%d9%84%d9%85%d8%a4%d8%b3%d8%b3%d8%a9/Index?ID=4&entText=LifeEventOwl",
		descrioption: "يضم خدمات النقل والمواصلات للأفراد.",
	},
	{
		image: image7,
		title: "الحصول على مسكن",
		link: "https://oman.om/%d8%a7%d9%84%d9%85%d8%a4%d8%b3%d8%b3%d8%a7%d8%aa-%d8%a7%d9%84%d8%ad%d9%83%d9%88%d9%85%d9%8a%d8%a9/%d8%aa%d9%81%d8%a7%d8%b5%d9%8a%d9%84-%d8%a7%d9%84%d9%85%d8%a4%d8%b3%d8%b3%d8%a9/Index?ID=6&entText=LifeEventOwl",
		descrioption: "يُسهّل الوصول إلى خدمات الحصول على منزل والخدمات العامة.",
	},
	{
		image: image8,
		title: "العائلة",
		link: "https://oman.om/%d8%a7%d9%84%d9%85%d8%a4%d8%b3%d8%b3%d8%a7%d8%aa-%d8%a7%d9%84%d8%ad%d9%83%d9%88%d9%85%d9%8a%d8%a9/%d8%aa%d9%81%d8%a7%d8%b5%d9%8a%d9%84-%d8%a7%d9%84%d9%85%d8%a4%d8%b3%d8%b3%d8%a9/Index?ID=1&entText=LifeEventOwl",
		descrioption: "يركّز على الخدمات الأسرية في سلطنة عمان.",
	},
	{
		image: image9,
		title: "التقاعد",
		link: "https://oman.om/%d8%a7%d9%84%d9%85%d8%a4%d8%b3%d8%b3%d8%a7%d8%aa-%d8%a7%d9%84%d8%ad%d9%83%d9%88%d9%85%d9%8a%d8%a9/%d8%aa%d9%81%d8%a7%d8%b5%d9%8a%d9%84-%d8%a7%d9%84%d9%85%d8%a4%d8%b3%d8%b3%d8%a9/Index?ID=7&entText=LifeEventOwl",
		descrioption: "يضم خدمات تُعنى بمرحلة التقاعد وكبار السن.",
	},
];

class Home extends Component {
	render() {
		return (
			<div className="page-content">
				<Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} className="breadcrumbs-style" aria-label="breadcrumb">
					{breadcrumbs}
				</Breadcrumbs>
				<div className="content">
					<div className="left">
						<Slide autoplay easing="cubic-out" pauseOnHover infinite>
							{slideImages.map((slideImage, index) => (
								<div key={index}>
									<div className="slide-image" style={{ backgroundImage: `url(${slideImage.url})` }}></div>
								</div>
							))}
						</Slide>
						<div className="content-section">
							<h2 className="section-title">الخدمات الأكثر إستخداما</h2>
							<ScrollContainer className="scroll-container">
								{services.map((service, index) => (
									<div key={index} className="scroll-card">
										<div className="card-content">
											<a className="card-title" href={service.link}>
												{service.title}
											</a>
											<p className="card-desc">{service.descrioption}</p>
										</div>
										<a className="card-nav" href={service.link}>
											اقرأ أكثر...
										</a>
									</div>
								))}
							</ScrollContainer>
						</div>
						<div className="content-section">
							<h2 className="section-title">مسارات الحياة (المواطنين)</h2>
							<ScrollContainer className="scroll-container">
								{lifeDirections.map((lifeDirection, index) => (
									<div key={index} className="scroll-card">
										<div className="card-content" style={{ height: 300 }}>
											<img className="card-image" src={lifeDirection.image} alt="" />
											<p className="card-title" style={{ color: "#000" }}>
												{lifeDirection.title}
											</p>
											<p className="card-desc">{lifeDirection.descrioption}</p>
										</div>
										<a className="card-nav" href={lifeDirection.link}>
											اقرأ أكثر...
										</a>
									</div>
								))}
							</ScrollContainer>
						</div>
						<div className="content-section">
							<div class="container">
								<div className="service-main">خدمات</div>
								<a
									href="/Khedma"
									className="service-card"
									style={{ gridArea: "card1", backgroundImage: `url(${bg3})` }}
								>
									<div className="service-card-content">
										<p className="card-value">479 خدمات</p>
										<p className="card-label">حماية المستهلك</p>
									</div>
								</a>
								<a
									href="https://oman.om/%d8%a7%d9%84%d9%85%d8%a4%d8%b3%d8%b3%d8%a7%d8%aa-%d8%a7%d9%84%d8%ad%d9%83%d9%88%d9%85%d9%8a%d8%a9/%d8%aa%d9%81%d8%a7%d8%b5%d9%8a%d9%84-%d8%a7%d9%84%d9%85%d8%a4%d8%b3%d8%b3%d8%a9/Index?ID=2&entText=sbsctr"
									className="service-card"
									style={{ gridArea: "card2", backgroundImage: `url(${bg5})` }}
								>
									<div className="service-card-content">
										<p className="card-value">361 خدمات</p>
										<p className="card-label">النقل والمواصلات</p>
									</div>
								</a>
								<a
									href="https://oman.om/%d8%a7%d9%84%d9%85%d8%a4%d8%b3%d8%b3%d8%a7%d8%aa-%d8%a7%d9%84%d8%ad%d9%83%d9%88%d9%85%d9%8a%d8%a9/%d8%aa%d9%81%d8%a7%d8%b5%d9%8a%d9%84-%d8%a7%d9%84%d9%85%d8%a4%d8%b3%d8%b3%d8%a9/Index?ID=11&entText=sbsctr"
									className="service-card"
									style={{ gridArea: "card3", backgroundImage: `url(${bg4})` }}
								>
									<div className="service-card-content">
										<p className="card-value">63 خدمات</p>
										<p className="card-label">العمل</p>
									</div>
								</a>
								<a
									href="https://oman.om/%d8%a7%d9%84%d9%85%d8%a4%d8%b3%d8%b3%d8%a7%d8%aa-%d8%a7%d9%84%d8%ad%d9%83%d9%88%d9%85%d9%8a%d8%a9/%d8%aa%d9%81%d8%a7%d8%b5%d9%8a%d9%84-%d8%a7%d9%84%d9%85%d8%a4%d8%b3%d8%b3%d8%a9/Index?ID=13&entText=sbsctr"
									className="service-card"
									style={{ gridArea: "card4", backgroundImage: `url(${bg1})` }}
								>
									<div className="service-card-content">
										<p className="card-value">1034 خدمات</p>
										<p className="card-label">تمويل الأعمال التجارية</p>
									</div>
								</a>
								<a
									href="https://oman.om/%d8%a7%d9%84%d9%85%d8%a4%d8%b3%d8%b3%d8%a7%d8%aa-%d8%a7%d9%84%d8%ad%d9%83%d9%88%d9%85%d9%8a%d8%a9/%d8%aa%d9%81%d8%a7%d8%b5%d9%8a%d9%84-%d8%a7%d9%84%d9%85%d8%a4%d8%b3%d8%b3%d8%a9/Index?ID=15&entText=sbsctr"
									className="service-card"
									style={{ gridArea: "card5", backgroundImage: `url(${bg6})` }}
								>
									<div className="service-card-content">
										<p className="card-value">69 خدمات</p>
										<p className="card-label">السياحة</p>
									</div>
								</a>
								<a
									href="https://oman.om/%d8%a7%d9%84%d9%85%d8%a4%d8%b3%d8%b3%d8%a7%d8%aa-%d8%a7%d9%84%d8%ad%d9%83%d9%88%d9%85%d9%8a%d8%a9/%d8%aa%d9%81%d8%a7%d8%b5%d9%8a%d9%84-%d8%a7%d9%84%d9%85%d8%a4%d8%b3%d8%b3%d8%a9/Index?ID=19&entText=sbsctr"
									className="service-card"
									style={{ gridArea: "card6", backgroundImage: `url(${bg2})` }}
								>
									<div className="service-card-content">
										<p className="card-value">277 خدمات</p>
										<p className="card-label">التعليم والبحث</p>
									</div>
								</a>
							</div>
							{/* <div className="container-actions">
								<button className="action-btn">المزيد من الخدمات</button>
							</div> */}
						</div>
						<div className="content-section">
							<img className="home-bottom-image" src={homeBottom} alt="" />
							<img className="home-bottom-image-mobile" src={homeBottomMobile} alt="" />
						</div>
					</div>
					<div className="right">
						<MenuBar />
					</div>
				</div>
			</div>
		);
	}
}

export default Home;
