import React, { Component } from 'react';
import './styles.scss';
import { withRouter } from 'react-router-dom';
import CloseIcon from '@mui/icons-material/Close';

class Modal extends Component {
	constructor(props) {
		super(props);
		this.onModalBgClick = this.onModalBgClick.bind(this);
	}

	onModalBgClick(e) {
		const { closeModal } = this.props;
		if (closeModal && e.target.className === 'modal-bg') {
			closeModal();
		}
	}

	render() {
		const { visible, children, title, closeModal, style } = this.props;
		return (
			<>
				{visible && (
					<div className="modal-bg" onMouseDown={this.onModalBgClick}>
						<div className="modal" style={style}>
							<div className="modal-header">
								<span className="modal-title">{title}</span>
								{closeModal && <CloseIcon className="close-button" onClick={() => closeModal()} />}
							</div>
							<div className="modal-content">{children}</div>
						</div>
					</div>
				)}
			</>
		);
	}
}

export default withRouter(Modal);
