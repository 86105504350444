import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import './styles.scss';

class Reject extends Component {
	render() {
		const id_num = this.props.match.params.id_num;
		return (
			<div className="reject-page">
				<p className="message-title">تم رفض الطلب !</p>
				<div className="order_content">
					<span className="title">يمكنك المحاولة مرة اخرى</span>
					<br />
					<span className="value">للمحاولة مرة اخرى </span>
					<NavLink className="value" to={'/register_three/' + id_num}>
						اضغط هنا
					</NavLink>
				</div>
			</div>
		);
	}
}

export default Reject;
