import React, { Component } from 'react';
import './styles.scss';

class Thankyou extends Component {

	render() {
		return (
			<div className="thank-you">
				<p className="message-title">تم تأكيد الطلب</p>
				<div className='order_content'>
					<span className='title'>رقم الطلب:</span>
					<span className='value'>{this.props.match.params.order_num}</span>
				</div>
			</div>
		);
	}
}

export default Thankyou;
