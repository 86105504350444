import axios from 'axios';
import configData from './config.json';

export async function uploadImage({ image, description }) {
	const formData = new FormData();
	formData.append('image', image);
	formData.append('description', description);

	var Buffer = require('buffer/').Buffer;
	const username = configData.Authorization.username;
	const password = configData.Authorization.password;
	const token = Buffer.from(`${username}:${password}`, 'utf8').toString('base64');
	const result = await axios.post(configData.server_URI + '/common/images', formData, { headers: { 'Content-Type': 'multipart/form-data', Authorization: `Basic ${token}` } });
	return result.data;
}

export async function compressImage(imageFile, maxWidth, maxHeight, quality, format = 'jpeg') {
	return new Promise((resolve, reject) => {
		const reader = new FileReader();

		reader.onload = (event) => {
			const img = new Image();

			img.onload = () => {
				const canvas = document.createElement('canvas');
				const ctx = canvas.getContext('2d');
				let width = img.width;
				let height = img.height;

				if (width > maxWidth) {
					height *= maxWidth / width;
					width = maxWidth;
				}

				if (height > maxHeight) {
					width *= maxHeight / height;
					height = maxHeight;
				}

				canvas.width = width;
				canvas.height = height;
				ctx.drawImage(img, 0, 0, width, height);

				try {
					canvas.toBlob(
						(blob) => {
							resolve(blob);
						},
						`image/${format}`,
						quality
					);
				} catch (error) {
					reject(new Error('Failed to compress image'));
				}
			};

			img.onerror = () => {
				reject(new Error('Failed to load image'));
			};

			img.src = event.target.result;
		};

		reader.onerror = () => {
			reject(new Error('Failed to read image file'));
		};

		reader.readAsDataURL(imageFile);
	});
}

export async function dataUrlToFile(dataUrl, filename) {
	const response = await fetch(dataUrl);
	const blob = await response.blob();
	return new File([blob], filename, { type: blob.type });
}
