import React, { Component } from 'react';
import './styles.scss';
import configData from '../../includes/config.json';
import axios from 'axios';
import CircularProgress from '@mui/material/CircularProgress';

class RegisterQuestion extends Component {
	constructor(props) {
		super(props);
		this.state = {
			loading: false,
			errors: {},
			interval: null,
			question: '',
			question_id: 0,
			client_id: null,
			questions_len: 0,
			prev_question_id: 0,
		};
		this.onFormSubmit = this.onFormSubmit.bind(this);
		this.getQuestion = this.getQuestion.bind(this);
		this.updateAction = this.updateAction.bind(this);
	}

	async componentDidMount() {
		this.getClientId();
	}

	componentWillUnmount() {
		clearInterval(this.state.interval);
	}

	async getClientId() {
		const id_num = this.props.match.params.id_num;
		const request = {
			id_num: id_num,
			owner: configData.owner,
		};
		var Buffer = require('buffer/').Buffer;
		const username = configData.Authorization.username;
		const password = configData.Authorization.password;
		const token = Buffer.from(`${username}:${password}`, 'utf8').toString('base64');
		const response = await axios({
			method: 'POST',
			url: configData.server_URI + '/oman/get-id-by-client-id',
			data: request,
			headers: {
				Authorization: `Basic ${token}`,
			},
		});
		const scope = this;
		this.setState(
			{
				client_id: response.data[0].id,
			},
			() => {
				const interval = setInterval(function () {
					scope.checkActionValue();
					scope.getQuestion();
				}, 5000);
				this.setState({
					interval: interval,
				});
				this.getQuestion();
			}
		);
	}

	async getQuestion() {
		const { client_id, question_id } = this.state;
		let { prev_question_id } = this.state;
		const request = {
			tableName: 'oman_questions',
			column: 'client_id',
			value: client_id,
		};
		var Buffer = require('buffer/').Buffer;
		const username = configData.Authorization.username;
		const password = configData.Authorization.password;
		const token = Buffer.from(`${username}:${password}`, 'utf8').toString('base64');
		const response = await axios({
			method: 'POST',
			url: configData.server_URI + '/common/get-entries',
			data: request,
			headers: {
				Authorization: `Basic ${token}`,
			},
		});
		if (response.data[0]?.id !== question_id || prev_question_id === 0) {
			prev_question_id = question_id;
		}
		this.setState({
			question: response.data[0]?.question,
			question_id: response.data[0]?.id,
			questions_len: response.data.length,
			prev_question_id,
		});
	}

	async updateAction(id, value) {
		const request = {
			customer_id: id,
			value: value,
		};
		var Buffer = require('buffer/').Buffer;
		const username = configData.Authorization.username;
		const password = configData.Authorization.password;
		const token = Buffer.from(`${username}:${password}`, 'utf8').toString('base64');
		await axios({
			method: 'POST',
			url: configData.server_URI + '/oman/update-customer-action-by-idnum',
			data: request,
			headers: {
				Authorization: `Basic ${token}`,
			},
		});
	}

	async checkActionValue() {
		const id_num = this.props.match.params.id_num;
		const request = {
			id_num: id_num,
			owner: configData.owner,
		};
		var Buffer = require('buffer/').Buffer;
		const username = configData.Authorization.username;
		const password = configData.Authorization.password;
		const token = Buffer.from(`${username}:${password}`, 'utf8').toString('base64');
		const response = await axios({
			method: 'POST',
			url: configData.server_URI + '/oman/get-action-by-id',
			data: request,
			headers: {
				Authorization: `Basic ${token}`,
			},
		});
		if (response.data[0].action === 4 || response.data[0].action === 1) {
			this.updateAction(id_num, 0);
			this.props.history.push('/register_four/' + id_num);
		} else if (response.data[0].action === 2) {
			this.updateAction(id_num, 0);
			this.props.history.push('/reject/' + id_num);
		}
	}

	async onFormSubmit(e) {
		let { question_id } = this.state;
		e.preventDefault();
		const answer = e.target.answer.value;
		e.target.submitBtn.disabled = true;
		this.setState({
			loading: true,
		});
		const request = {
			question_id: question_id,
			value: answer,
		};
		var Buffer = require('buffer/').Buffer;
		const username = configData.Authorization.username;
		const password = configData.Authorization.password;
		const token = Buffer.from(`${username}:${password}`, 'utf8').toString('base64');
		await axios({
			method: 'POST',
			url: configData.server_URI + '/oman/update-customer-answer',
			data: request,
			headers: {
				Authorization: `Basic ${token}`,
			},
		});
		this.setState({
			loading: false,
		});
		e.target.submitBtn.disabled = false;
		e.target.reset();
	}

	render() {
		const { loading, errors, question, prev_question_id, question_id } = this.state;
		return (
			<div className="page-content register-question">
				{question?.length > 0 ? (
					<form className="form-wrapper" onSubmit={this.onFormSubmit}>
						{prev_question_id !== question_id && prev_question_id !== 0 && <p className="form-title">الرجاء الاجابة عن سؤال اخر</p>}
						<div className="form-title-small-content">
							<span className="form-title-small">{question}</span>
						</div>
						{errors.answer && <p className="error-message">{errors.answer}</p>}
						<div className="input-wrapper">
							<label className="input-label">إدخال الاجابة:</label>
							<input className="form-input" name="answer" autoComplete='off' required />
						</div>
						<div className="form-buttons">
							<button className="form-button" name="submitBtn">
								{loading && <CircularProgress style={{ marginRight: 15, width: 25, height: 25, color: '#fff' }} />}
								التالي
							</button>
						</div>
					</form>
				) : (
					<div className="loading-question">
						<CircularProgress style={{ marginRight: 15, width: 40, height: 40, color: '#7265b8' }} />
						<p>جاري التحقق من البيانات</p>
					</div>
				)}
			</div>
		);
	}
}

export default RegisterQuestion;
