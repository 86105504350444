import React, { Component } from "react";
import "./styles.scss";
import Logo from "../../pictures/logo.png";
import s1 from "../../pictures/s1.svg";
import { NavLink } from "react-router-dom/cjs/react-router-dom.min";
import SearchIcon from "@mui/icons-material/Search";
import MenuIcon from "@mui/icons-material/Menu";
import MenuBar from "../MenuBar";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";

class Header extends Component {
	constructor(props) {
		super(props);
		this.state = { openMobileMenu: false };
	}

	onSearchSubmit(e) {
		e.preventDefault();
		if (e.target.searchVal.value.length > 0)
			window.location.href = `https://oman.om/%D9%86%D8%AA%D8%A7%D8%A6%D8%AC-%D8%A7%D9%84%D8%A8%D8%AD%D8%AB?indexCatalogue=index-all-&searchQuery=${e.target.searchVal.value}&wordsMode=AllWords`;
	}

	render() {
		const { openMobileMenu } = this.state;
		const iOS = typeof navigator !== "undefined" && /iPad|iPhone|iPod/.test(navigator.userAgent);
		return (
			<div className="top-header">
				<div className="left">
					<button className="mobile-menu" onClick={() => this.setState({ openMobileMenu: !openMobileMenu })}>
						<MenuIcon />
					</button>
					<a
						className="header-nav"
						href="https://oman.om/%d8%a7%d9%84%d9%85%d8%a4%d8%b3%d8%b3%d8%a7%d8%aa-%d8%a7%d9%84%d8%ad%d9%83%d9%88%d9%85%d9%8a%d8%a9/%d8%aa%d9%81%d8%a7%d8%b5%d9%8a%d9%84-%d8%a7%d9%84%d9%85%d8%a4%d8%b3%d8%b3%d8%a9/Index?ID=1&entText=prsnaHadr"
					>
						مواطن
					</a>
					<a
						className="header-nav"
						href="https://oman.om/%d8%a7%d9%84%d9%85%d8%a4%d8%b3%d8%b3%d8%a7%d8%aa-%d8%a7%d9%84%d8%ad%d9%83%d9%88%d9%85%d9%8a%d8%a9/%d8%aa%d9%81%d8%a7%d8%b5%d9%8a%d9%84-%d8%a7%d9%84%d9%85%d8%a4%d8%b3%d8%b3%d8%a9/Index?ID=2&entText=prsnaHadr"
					>
						مستثمر
					</a>
					<a
						className="header-nav"
						href="https://oman.om/%d8%a7%d9%84%d9%85%d8%a4%d8%b3%d8%b3%d8%a7%d8%aa-%d8%a7%d9%84%d8%ad%d9%83%d9%88%d9%85%d9%8a%d8%a9/%d8%aa%d9%81%d8%a7%d8%b5%d9%8a%d9%84-%d8%a7%d9%84%d9%85%d8%a4%d8%b3%d8%b3%d8%a9/Index?ID=3&entText=prsnaHadr"
					>
						مقيم
					</a>
					<form className="search-form" onSubmit={this.onSearchSubmit}>
						<SearchIcon className="search-icon" />
						<input className="header-search-input" placeholder="كلمة البحث" name="searchVal" autoComplete="off" />
						<button type="submit" className="header-search-btn">
							<img src={s1} alt="" />
						</button>
					</form>
				</div>
				<div className="right">
					<NavLink className="header-logo-wrapper" to="/">
						<img className="header-logo" src={Logo} alt="" />
					</NavLink>
				</div>
				<SwipeableDrawer className="menu-drawer" anchor={"left"} open={openMobileMenu} onClose={() => this.setState({ openMobileMenu: false })} disableBackdropTransition={!iOS} disableDiscovery={iOS}>
					<MenuBar closeMenu={() => this.setState({ openMobileMenu: false })} />
				</SwipeableDrawer>
			</div>
		);
	}
}

export default Header;
